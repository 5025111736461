<template>
  <div class="campaign-list-page">
    <h2>Campaigns</h2>
    <div class="d-flex justify-content-between align-items-center py-3">
      <form method="get">
        <div class="input-group input-group-search">
          <input type="text" name="keyword" class="form-control" placeholder="Search campaign...">
          <div class="input-group-append">
            <button class="btn btn-outline-secondary" type="submit">
              <b-icon-search />
            </button>
          </div>
        </div>
      </form>

      <router-link :to="{ name: 'campaign-create' }" class="btn btn-primary">
        <i class="bi-plus"></i>
        Create new campaign
      </router-link>
    </div>

    <hr class="my-0">

    <div class="simple-filter py-4">
      <span>Status: </span>
      <a class="active" href="">All</a> |
      <a class="" href="">Drafted</a> |
      <a class="" href="">Published</a> |
      <a class="" href="">Closed</a>
    </div>

    <smart-table
      :header="header"
      :items="items"
    />
  </div>
</template>

<script>
import SmartTable from '../components/SmartTable.vue'

export default {
  name: 'CampaignList',

  components: {
    SmartTable
  },

  computed: {
    items () {
      const items = this.data ? this.data.data : []
      return items.map(item => {
        return {
          ...item,
          name: {
            text: item[`name_${this.currentLocale}`],
            to: {
              name: 'campaign-edit',
              params: { id: item.id }
            }
          },
          created_at: new Date(item.created_at)
        }
      })
    }
  },

  data () {
    return {
      header: [
        { data: 'Campaign URL', field: 'slug' },
        { data: 'Campaign Name', field: 'name', type: 'link' },
        { data: 'Total', field: 'totalAmount', type: 'money' },
        { data: 'Donors', field: 'donorCount', type: 'number' },
        { data: 'Created', field: 'created_at', type: 'datetime', attributes: { width: 150 } }
      ],

      data: null
    }
  },

  mounted () {
    this.fetch()
  },

  methods: {
    async fetch () {
      this.loading = true
      try {
        const resp = await this.$campaigns.list()
        this.data = resp.data
      }
      catch (e) {
        console.error(e.message)

      }
    }
  }
}
</script>

<style lang="scss" scoped>
</style>
